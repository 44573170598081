<template>
  <v-container class="mainContainer" grid-list-xs fluid>
    <p class="textHeading">Select Preview Type</p>
    <v-row class="row">
      <v-col class="pb-0" cols="6">
        <v-hover v-slot="{ hover }">
          <v-btn
            depressed
            @click="setSelectedType('structural')"
            :value="structuralPreview"
            class="button"
            :style="`${
              hover
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }; ${
              selectedType == 'structural'
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }`"
            >Skeleton</v-btn
          >
          <!-- :style=`
              ${hover
                ? 'color:white; background-color:#2a51e8;'
                : 'background-color:#fff;color:#2a51e8;'}
            ` -->
        </v-hover>
      </v-col>
      <v-col class="pb-0" cols="6">
        <v-hover v-slot="{ hover }">
          <v-btn
            @click="setSelectedType('real')"
            depressed
            :style="`${
              hover
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }; ${
              selectedType == 'real'
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }`"
            class="button"
            >Real</v-btn
          >
        </v-hover>
      </v-col>
    </v-row>
    <p class="textHeading">Device</p>
    <v-row class="row">
      <v-col class="pb-0" cols="6">
        <v-hover v-slot="{ hover }">
          <v-btn
            depressed
            @click="setSelectedDevice('android')"
            :style="`${
              hover
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }; ${
              selectedDevice == 'android'
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }`"
            class="button"
            >Android</v-btn
          >
        </v-hover>
      </v-col>
      <v-col class="pb-0" cols="6">
        <v-hover v-slot="{ hover }">
          <v-btn
            depressed
            @click="setSelectedDevice('ios')"
            :style="`${
              hover
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }; ${
              selectedDevice == 'ios'
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }`"
            class="button"
            >iOS</v-btn
          >
        </v-hover>
      </v-col>
      <v-col class="pb-0" cols="6">
        <v-hover v-slot="{ hover }">
          <v-btn
            depressed
            @click="setSelectedDevice('ipad')"
            :style="`${
              hover
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }; ${
              selectedDevice == 'ipad'
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }`"
            class="button"
            >iPad</v-btn
          >
        </v-hover>
      </v-col>
      <v-col class="pb-0" cols="6">
        <v-hover v-slot="{ hover }">
          <v-btn
            depressed
            @click="setSelectedDevice('desktop')"
            :style="`${
              hover
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }; ${
              selectedDevice == 'desktop'
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }`"
            class="button"
            >Desktop</v-btn
          >
        </v-hover>
      </v-col>
    </v-row>
    <p class="textHeading">Data {{ selectedDataType }}</p>
    <v-row>
      <v-col cols="6">
        <v-hover v-slot="{ hover }">
          <v-btn
            depressed
            @click="setSelectedDataType('dummy')"
            :style="`${
              hover
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }; ${
              selectedDataType == 'dummy'
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }`"
            class="button"
            >Dummy</v-btn
          >
        </v-hover>
      </v-col>
      <v-col class="pb-0" cols="6">
        <v-hover v-slot="{ hover }">
          <v-btn
            depressed
            @click="setSelectedDataType('context')"
            :style="`${
              hover
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }; ${
              selectedDataType == 'context'
                ? 'color:white; background-color:#2a51e8'
                : 'background-color:#fff;color:#2a51e8'
            }`"
            class="button"
            >Context</v-btn
          >
        </v-hover>
      </v-col>
    </v-row>

    <v-btn
      depressed
      class="previewButton btn btn-1"
      style="color: white; background-color: #2a51e8"
      >Preview</v-btn
    >
  </v-container>
</template>

<script>
import { createNamespacedHelpers as cnh } from 'vuex'

const { mapState, mapMutations, mapGetters } = cnh('PreviewStore')
export default {
  name: 'PreviewPanel',
  computed: {
    ...mapState(['structuralPreview']),
    ...mapGetters(['selectedType', 'selectedDevice', 'selectedDataType'])
  },
  created() {
  },
  methods: {
    ...mapMutations([
      'setStructuralPreview',
      'setSelectedType',
      'setSelectedDevice',
      'setSelectedDataType',
      'setRotation'
    ]),
    toggleStructuralPreview() {
      console.log(`[this.structuralPreview]: `, this.structuralPreview)
      this.setStructuralPreview(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn{
  transition: var(--transition);
  background-size: var(--background-size);
}
.btn:hover{
  background-position: var(--background-position);
}
.btn-1{
  background-image: var(--background-image);
}
.mainContainer {
  padding-left: 9%;
  //   border: 1px solid grey;
  //   max-height: 86vh;
  //   min-height: 86vh;
  //   width: 100%;
  //   overflow-y: auto;
  //   overflow: hidden;
  //   box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
  //     rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.textHeading {
  font-weight: bold;
  margin-bottom: 8px;
}

.button {
  width: 120px;
  text-transform: unset;
  border: 1px solid $royal-blue;
  color: $royal-blue;

  /* background-color: #fff;*/
}

.previewButton {
  margin-left: 75px;
}
.row {
  width: 270px;
  margin-bottom: 15px;
}
</style>