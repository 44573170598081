var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mainContainer",attrs:{"grid-list-xs":"","fluid":""}},[_c('p',{staticClass:"textHeading"},[_vm._v("Select Preview Type")]),_c('v-row',{staticClass:"row"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"button",style:(((hover
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8') + "; " + (_vm.selectedType == 'structural'
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8'))),attrs:{"depressed":"","value":_vm.structuralPreview},on:{"click":function($event){return _vm.setSelectedType('structural')}}},[_vm._v("Skeleton")])]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-btn',{staticClass:"button",style:(((hover
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8') + "; " + (_vm.selectedType == 'real'
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8'))),attrs:{"depressed":""},on:{"click":function($event){return _vm.setSelectedType('real')}}},[_vm._v("Real")])]}}])})],1)],1),_c('p',{staticClass:"textHeading"},[_vm._v("Device")]),_c('v-row',{staticClass:"row"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-btn',{staticClass:"button",style:(((hover
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8') + "; " + (_vm.selectedDevice == 'android'
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8'))),attrs:{"depressed":""},on:{"click":function($event){return _vm.setSelectedDevice('android')}}},[_vm._v("Android")])]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-btn',{staticClass:"button",style:(((hover
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8') + "; " + (_vm.selectedDevice == 'ios'
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8'))),attrs:{"depressed":""},on:{"click":function($event){return _vm.setSelectedDevice('ios')}}},[_vm._v("iOS")])]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-btn',{staticClass:"button",style:(((hover
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8') + "; " + (_vm.selectedDevice == 'ipad'
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8'))),attrs:{"depressed":""},on:{"click":function($event){return _vm.setSelectedDevice('ipad')}}},[_vm._v("iPad")])]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-btn',{staticClass:"button",style:(((hover
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8') + "; " + (_vm.selectedDevice == 'desktop'
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8'))),attrs:{"depressed":""},on:{"click":function($event){return _vm.setSelectedDevice('desktop')}}},[_vm._v("Desktop")])]}}])})],1)],1),_c('p',{staticClass:"textHeading"},[_vm._v("Data "+_vm._s(_vm.selectedDataType))]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-btn',{staticClass:"button",style:(((hover
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8') + "; " + (_vm.selectedDataType == 'dummy'
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8'))),attrs:{"depressed":""},on:{"click":function($event){return _vm.setSelectedDataType('dummy')}}},[_vm._v("Dummy")])]}}])})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-btn',{staticClass:"button",style:(((hover
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8') + "; " + (_vm.selectedDataType == 'context'
              ? 'color:white; background-color:#2a51e8'
              : 'background-color:#fff;color:#2a51e8'))),attrs:{"depressed":""},on:{"click":function($event){return _vm.setSelectedDataType('context')}}},[_vm._v("Context")])]}}])})],1)],1),_c('v-btn',{staticClass:"previewButton btn btn-1",staticStyle:{"color":"white","background-color":"#2a51e8"},attrs:{"depressed":""}},[_vm._v("Preview")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }